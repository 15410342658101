<template>
    <div class="display-flex row-wrap" v-show="!isLoading">
        <div class="flex-1 team-info__overview-left">
            <div>
                <div class="team-info__overview-card" >
                    <div class="team-info__overview-header">{{$t('GENERAL')}}</div>
                    <div class="team-info__overview-body">
                        <div class="team-info__overview-body__left">{{$t('NAME')}}</div>
                        <div class="team-info__overview-body__right">{{teamInfo.name === null || teamInfo.name === '' ? '-':teamInfo.name}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="team-info__overview-body__left">{{$t('COUNTRY')}}</div>
                        <div class="team-info__overview-body__right">{{teamInfo.country === null || teamInfo.country === ''  ? '-':teamInfo.country}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="team-info__overview-body__left">{{$t('FOUNDED')}}</div>
                        <div class="team-info__overview-body__right">{{teamInfo.founded === null || teamInfo.founded === '' ? '-':teamInfo.founded}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="team-info__overview-body__left">{{$t('VENUE')}}</div>
                        <div class="team-info__overview-body__right">{{teamInfo.venue_name === null || teamInfo.venue_name === '' ? '-':teamInfo.venue_name}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="team-info__overview-body__left">{{$t('VENUE_ADDRESS')}}</div>
                        <div class="team-info__overview-body__right">{{teamInfo.venue_address === null || teamInfo.venue_address === '' ? '-' : teamInfo.venue_address}}</div>
                    </div>
                    <div class="team-info__overview-body" >
                        <div class="team-info__overview-body__left">{{$t('VENUE_CITY')}}</div>
                        <div class="team-info__overview-body__right">{{teamInfo.venue_city === null || teamInfo.venue_city === '' ? '-':teamInfo.venue_city}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="team-info__overview-body__left">{{$t('VENUE_CAPACITY')}}</div>
                        <div class="team-info__overview-body__right">{{teamInfo.venue_capacity === null || teamInfo.venue_capacity === '' ? '-':teamInfo.venue_capacity}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="team-info__overview-body__left">{{$t('COACH')}}</div>
                        
                        <div class="team-info__overview-body__right">{{formatCoach(teamInfo)}}</div>
                    </div>
                </div>
                <!-- <div class="team-info__overview-card">
                    <div class="team-info__overview-header">{{$t('TROPHIES')}}</div>
                    <div class="team-info__overview-body"  v-show="teamInfo.honours !== null" v-for="(o, index) in teamInfo.honours" :key="index">
                        <div class="">
                            <span>{{$t(o.country)}}</span>
                            <span class="mlr-02rem">:</span>
                            <span>{{$t(o.league)}}</span>
                            <span class="mlr-02rem">-</span>
                            <span>{{$t(o.status)}}</span>
                        </div>
                        <div class="">{{o.count}}</div>
                    </div>
                    <div class="no-data"  v-show="teamInfo.honours == null">{{$t('NO_TROPHIES')}}</div>
                </div> -->
            </div>
        </div>
        <div class="flex-2 display-flex team-info__transfer">
            <div class="flex-1 team-info__transfer-in">
                <div>
                     <div class="team-info__overview-header">{{$t('TRANSFER_IN')}}</div>
                    <div class="team-info__overview-card">
                        <div class="team-info__overview-body__header">
                            <div class="team-info__overview-goal__player">
                                <div >{{$t('PLAYER')}}</div>                          
                            </div>
                            <div class="display-flex team-info__overview-goal__player-goal__title">
                                <div class="team-info__overview-goal__player-goal">{{$t('FROM/DATE')}}</div>
                            </div>
                        </div>
                        <div v-if="teamInfo.transferInData">
                             <Navigation :id="o.id" :category="'player'" :name="o.name"  class="team-info__overview-body"  v-for="(o, index) in teamInfo.transferInData" :key="index">
                                <div class="team-info__overview-goal__player">
                                        <Icon  :category="'player'"  :logoUrl=o.logo  class="team-info__overview-goal__player-img"></Icon>
                                    <div class="team-info__overview-goal__player-name">{{o.name}}</div>
                                </div>
                                <div class="display-flex team-info__overview-goal__player-goal__title flex-col tr">
                                    <div class="team-info__overview-goal__player-goal">{{$t(o.from)}}</div>                        
                                    <div class="team-info__overview-goal__player-goal">{{o.date}}</div>                        
                                </div>                      
                             </Navigation>
                        </div>
                       <div v-else class="no-data">{{$t('NO_TRANSFER_IN')}}</div>
                    </div>
                </div>
                <div>
                    <div class="team-info__overview-header">{{$t('TRANSFER_OUT')}}</div>
                    <div class="team-info__overview-card">
                        <div class="team-info__overview-body__header">
                            <div class="team-info__overview-goal__player">
                                <div >{{$t('PLAYER')}}</div>                          
                            </div>
                            <div class="display-flex team-info__overview-goal__player-goal__title">
                                <div class="team-info__overview-goal__player-goal">{{$t('DATE')}}</div>
                            </div>
                        </div>
                        <div  v-if="teamInfo.transferOutData">
                             <Navigation :id="o.id" :category="'player'" :name="o.name"  class="team-info__overview-body"  v-for="(o, index) in teamInfo.transferOutData" :key="index">
                                <div class="team-info__overview-goal__player">
                                        <Icon  :category="'player'"  :logoUrl=o.logo  class="team-info__overview-goal__player-img"></Icon>
                                    <div class="team-info__overview-goal__player-name">{{o.name}}</div>
                                </div>
                                <div class="display-flex team-info__overview-goal__player-goal__title flex-col tr">
                                    <div class="team-info__overview-goal__player-goal">{{o.from}}</div>                        
                                    <div class="team-info__overview-goal__player-goal">{{o.date}}</div>                        
                                </div>                      
                            </Navigation>
                        </div>
                          <div v-else class="no-data">{{$t('NO_TRANSFER_OUT')}}</div>
                    </div>
                </div>
            </div>
            <div class="flex-1" >
                   <!-- <div class="team-info__overview-header">{{$t('SIDELINE')}}</div>
                  <div class="team-info__overview-card" >
                        <div class="team-info__overview-body__header">
                            <div class="team-info__overview-goal__player">
                                <div >{{$t('PLAYER')}}</div>                          
                            </div>
                            <div class="display-flex team-info__overview-goal__player-goal__title">
                                <div class="team-info__overview-goal__player-goal">{{$t('DESCRIPTION/DATE')}}</div>
                            </div>
                        </div>
                        <div v-if="teamInfo.sidelinedData">
                            <Navigation :id="o.id" :category="'player'" :name="o.name"  class="team-info__overview-body"  v-for="(o, index) in teamInfo.sidelinedData" :key="index">
                                <div class="team-info__overview-goal__player">
                                        <Icon   :category="'player'" :logoUrl=o.logo  class="team-info__overview-goal__player-img"></Icon>
                                    <div class="team-info__overview-goal__player-name">{{o.name}}</div>
                                </div>
                                <div class="display-flex team-info__overview-goal__player-goal__title flex-col tr">
                                    <div class="team-info__overview-goal__player-goal">{{o.description}}</div>                        
                                    <div class="team-info__overview-goal__player-goal">{{o.startdate}}</div>                        
                                </div>                                              
                             </Navigation>
                        </div>
                        
                        <div v-else class="no-data">{{$t('NO_SIDELINE')}}</div>
                  </div> -->
                  
                  <div class="team-info__overview-card">
                    <div class="team-info__overview-header">{{$t('ABOUT')}}</div>
                    <About></About>
                  </div>
            </div>
        </div>
    </div>
    <Loading v-show="isLoading"></Loading>
</template>

<script>

import {mapGetters,mapActions} from 'vuex'
import Loading from '@/components/indicator/Loading.vue'
import About from '@/views/matchs/team/basketball/About.vue';
export default {
    components: {
        About,
    },
    computed: {
       
      ...mapGetters([
            'teamInfo',
            'isMobile',
            'currentLocale',
            'currentSportType'
        ]),
    },
    data() {
        return {
            isLoading:true
        }
    },
     watch:{
        teamInfo:{
            deep:true,
            handler(newVal,oldVal) {
                this.isLoading = false;
            }
        },
      
     },
     mounted() {
         if (this.teamInfo.id){
            this.isLoading = false;
         }
     },
    methods: {
        formatCoach(data){
            if (data.coach) {
                if (this.currentLocale === 'zh-Hans') {
                    return data.coach.nameCn
                } else {
                      return data.coach.nameEn
                }
            } else {
                return "-"
            }
        }
    },
  
}
</script>

<style>
    .team-info__overview-left {
        margin-right:2rem;
    }   
    .team-info__overview-about{
        line-height: 1rem;
    }
    .team-info__transfer-in{
        margin-right: 2rem;
    }
    @media (max-width: 768px){
        .team-info__overview-left {
            flex:none;
            width:100%;
            margin-right: 0;
        }
        .team-info__transfer{
            flex-direction: column;
        }
         .team-info__transfer-in{
            margin-right: 0;
        }
    }
</style>